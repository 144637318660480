/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img"
  }, _provideComponents(), props.components), {ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "3-awesome-hearing-aid-features-you-need-to-know-about",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-awesome-hearing-aid-features-you-need-to-know-about",
    "aria-label": "3 awesome hearing aid features you need to know about permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3 Awesome Hearing Aid Features You Need to Know About"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aid technology has advanced at the speed of light. We’re now seeing devices with tons of cool features that are on par with today’s smartphones. If you’re currently in the market for hearing aids, you’re going to want to check out these 3 awesome hearing aid features."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeable-hearing-aids-make-life-easier",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-hearing-aids-make-life-easier",
    "aria-label": "rechargeable hearing aids make life easier permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable hearing aids make life easier"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aid-tech.jpg",
    alt: "Signia hearinfg aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most common things we hear from customers is, “I hate those tiny batteries!” Today, the majority of hearing aids are rechargeable. This means no more messing around with those tiny batteries. These hearing aids will last all day on a single charge, and some even come with portable chargers just like Apple’s AirPods."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connect-directly-to-your-smartphone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connect-directly-to-your-smartphone",
    "aria-label": "connect directly to your smartphone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connect directly to your smartphone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you have an iPhone or Android, we have a wide variety of hearing aids that connect directly to your phone. You can now stream phone calls, music or a podcast directly to your hearing aids with no additional devices needed. You can also control settings, adjust volume and even track your health with some of the major hearing aid apps. It’s never been easier to stay connected with today’s hearing technology."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "amazing-clarity-and-comfort",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#amazing-clarity-and-comfort",
    "aria-label": "amazing clarity and comfort permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Amazing clarity and comfort"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Did you know hearing aids are 50x faster than the ones from five years ago? Faster processing allows for huge improvements in speech clarity. Why is this important? Because even though you might be able to hear loud enough, hearing loss affects your ability to understand what is being said. Also, people might sound like they’re mumbling all the time."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But the problem is not them…it’s you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is why today’s hearing aids are specifically designed to help you understand every word comfortably, even in noisy environments like restaurants and shopping malls. The devices use advanced speech-processing algorithms to enhance a person’s voice while reducing background noise automatically. In addition, there’s extra bandwidth for enhanced feedback cancellation, wind noise reduction, surround sound, and more."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all these advancements, we hear the experience is more “natural” and “effortless”, which is the ultimate feedback."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "experience-the-latest-hearing-aid-technology-for-30-days",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#experience-the-latest-hearing-aid-technology-for-30-days",
    "aria-label": "experience the latest hearing aid technology for 30 days permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Experience the latest hearing aid technology for 30 days"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids aren’t a spur of the moment decision. So if you have any questions about the latest hearing aids, we’re happy to help. We also know that the only way to experience these 3 awesome hearing aid features is to try them for yourself. If you haven’t taken your next step, just click the button below. You’ll have the opportunity to secure a 30-day trial with hear.com."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
